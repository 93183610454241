import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { UserService } from "../../../../shared/services/user.service";
import { CentroCustoService } from "../../../../clientes/visualizar/centro-custo/centro-custo.service";
import { ClienteService } from "../../../../clientes/cliente.service";
import { VeiculoService } from "../../../../veiculos/veiculo.service";
import { RegraService } from "../../regras.service";
import Swal from "sweetalert2";
import { DualList } from "src/app/modulos/shared/components/dual-list/dual-list";

@Component({
  selector: "cadastrar-regras-perfil",
  templateUrl: "./cadastrar-perfil-regras.component.html",
})
export class CadastrarPerfilRegrasComponent implements OnInit {
  format = DualList.format;
  dados = {
    idperregra: "",
    cnome: "",
    lativo: "1",
    veiculos: [],
    idcliente: "",
    idregional: 0,
    veiculosSelec: [],
  };

  conteudoCarregado = false;
  temCC = false;

  regionais = [];

  @Output() cancelar = new EventEmitter<number>();

  constructor(
    private regraService: RegraService,
    private veiculoService: VeiculoService,
    private clienteService: ClienteService,
    private centroCustoService: CentroCustoService,
    private userService: UserService
  ) {
    this.veiculoService
      .getpagination({ idcliente: this.clienteService.getCliente().idcliente })
      .subscribe((response) => {
        if (response.success) {
          this.dados.veiculos = response.veiculos;
          this.dados.veiculos.forEach((veiculo) => {
            veiculo.label =
              veiculo.placa +
              " - " +
              veiculo.marca +
              " - " +
              veiculo.modelo +
              " - " +
              veiculo.tipofrota;
          });
        }

        this.conteudoCarregado = true;
      });
  }

  ngOnInit(): void {
    this.getCentrodeCusto();
  }

  voltarPesquisa(): void {
    this.cancelar.emit(0);
  }

  salvar(dados: any): void {
    dados.idcliente = this.clienteService.getCliente().idcliente;

    Swal.fire({
      title: "Cadastrar perfil?",
      text: "Você está prestes a cadastrar um novo perfil de regras",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, cadastre!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let arrVeicId = DualList.objectToId(dados.veiculosSelec, "idveiculo");
        return this.regraService.addPerfilRegras({
          ...dados,
          veiculosSelec: arrVeicId,
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire({
            title: "",
            text: "Perfil de regras cadastrado com sucesso!",
            icon: "success",
            showCancelButton: false,
          }).then(() => {
            this.cancelar.emit(1);
          });
        } else {
          Swal.fire("", "Erro ao cadastrar!", "error");
        }
      }
    });
  }

  getCentrodeCusto(): void {
    this.centroCustoService
      .getCentroCustoList({
        idcliente: this.clienteService.getCliente().idcliente,
      })
      .subscribe((response) => {
        if (response.success) {
          this.regionais = response.centrocusto;
          this.regionais.forEach((regional) => {
            regional.label = regional.codcc + " - " + regional.cc;
          });

          if (this.userService.usuario.centrocusto != 0) {
            this.temCC = true;
            this.dados.idregional = this.userService.usuario.centrocusto;
          }
        }
      });
  }
}
